import useAuth from "hooks/useAuth";
import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AxiosService from "services/Axios";

const FortuneContext = createContext();

const FortuneProvider = ({ children }) => {
  const [fortuneData, setFortuneData] = useState({});
  const { user } = useAuth();

  const getFortune = async (year = false) => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/auth/fortune`;
      if (year) {
        url = `${process.env.REACT_APP_API_URL}/auth/fortune/${year}`;
      }else{
        year = user?.dob;
      }
      const res = await AxiosService.get(url);
      setFortuneData((prevData) => ({
        ...prevData,
        [year]: res.data.responseObject,
      }));
    } catch (error) {
      toast.error("데이터를 불러오는데 오류가 발생했습니다.", error);
      throw error;
    }
  };

  useEffect(() => {
    if (user?.name && user?.dob) {
      getFortune();
      getFortune(2024);
      getFortune(2025);
    }
  }, [user]);

  return (
    <FortuneContext.Provider
      value={{
        fortune2024: fortuneData[2024] || null,
        fortune2025: fortuneData[2025] || null,
        fortune: fortuneData[user?.dob] || null,
        getFortune,
      }}
    >
      {children}
    </FortuneContext.Provider>
  );
};

export { FortuneContext, FortuneProvider };
