import PropTypes from 'prop-types';
import React from 'react';
import Header from 'layouts/WebsiteLayout/Header';
import Footer from 'layouts/WebsiteLayout/Footer';

const WebsiteLayout = ({ children }) => {
  return (
    <div className='content'>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

WebsiteLayout.propTypes = {
  children: PropTypes.node
};
export default WebsiteLayout;
