import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const [activeTab, setActiveTab] = useState('/');
  const navigate = useNavigate();

  const handleClick = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  return (
    <footer className='footer__wrapper container'>
      <Row>
        <Col
          className={`col ${activeTab === '/' ? 'active' : ''}`}
          onClick={() => handleClick('/')}
        >
          홈
        </Col>
        <Col
          className={`col ${activeTab === '/test' ? 'active' : ''}`}
          onClick={() => handleClick('/test')}
        >
          소름테스트
        </Col>
        <Col
          className={`col ${activeTab === '/mypage' ? 'active' : ''}`}
          onClick={() => handleClick('/mypage')}
        >
          마이페이지
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;