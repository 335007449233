import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Sorm } from 'assets/images/logoanimated.svg'
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const Header = () => {
  const { isLoggedIn, signOut } = useAuth();

  return (
    <header className='header__wrapper'>
      <div className='logo'>
        <Link to="/">
          <Sorm className="logo"></Sorm>
        </Link>
      </div>
      {/* <div className='btn_wrapper'>
        {
          isLoggedIn() ?
            (
              <Button variant="primary" onClick={signOut}>
                Sign-out
              </Button>
            ) :
            (
              <Link to="/signin">
                <Button variant="primary">
                  로그인
                </Button>
              </Link>
            )
        }

      </div> */}
    </header>
  );
};

export default Header;
