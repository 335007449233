import React from 'react';

const NotFound = () => (
  <div className='not-found__wrapper'>
    <h6>404 - Page Not Found</h6>
    <p>페이지를 찾을 수 없습니다.</p>
  </div>
);

export default NotFound;
