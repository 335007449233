import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const AxiosService = axios.create();

AxiosService.interceptors.request.use(async (config) => {
  config.timeout = 60000; // Wait for 60 seconds before timing out
  const token = Cookies.get('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

AxiosService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {

    // store.dispatch(setLoading(false));

    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      // Handle Timeout
      toast.error("Request timed out")

    } else if (error.response.status === 401) {
      // Handle 401 error here
//      toast.error(error.response.data ? error.response.data : "Unauthorized: Redirecting to login page...")
      toast.error(error.response.data ? error.response.data : "유효하지 않은 접근입니다. 로그인 페이지로 이동합니다.")
      Cookies.remove('token');
      window.location.href = '/signin';

    } else if (error.response.status === 400) {
      // Handle 400 error here

      toast.error(error.response.data && error.response.data.message ? error.response.data.message : (typeof error.response.data === 'string' ? error.response.data : "Bad Request"))

    } else if (error.response.status === 500) {
      // Handle 500 error here
//      toast.error(error.response.data ? error.response.data : "The server encountered an error and couldn't complete your request.")
      toast.error(error.response.data ? error.response.data : "오류가 발생했습니다. 요청을 완료할 수 없습니다.")
    } else if (error.response.status === 403) {
      // Handle 403 error here
    }
    //reject the Promise and propagate the error to the caller
    return Promise.reject(error);
  }
);

export default AxiosService;